import React from "react"
import servicesBg from "../images/services-bg.png"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

export default function ServicesSection() {
  return (
    <div id="Services" className="services-section">
      <img alt="Services Background Image" className="services-section__bg" src={servicesBg} />
      <div className="services-section__content">
        <Tabs>
          <TabList>
            <Tab>Executive Coaching</Tab>
            <Tab>Strategic &amp; Annual Planning Facilitation</Tab>
            <Tab>Culture, Communication, &amp; Alignment</Tab>
            <Tab>Change Management</Tab>
            <Tab>Focus &amp; Accountability</Tab>
            <Tab>Keynote Presentations &amp; Event Planning</Tab>
          </TabList>

          <TabPanel>
            <p>
              With more than 25 years of corporate, coaching and management
              experience, Adrienne will help you to achieve your personal and
              professional goals to help you become the best you can be!
            </p>
          </TabPanel>
          <TabPanel>
            <p>
              Would you like to have an event that is engaging and effective?
              Something that strengthens your team and creates a living,
              executable plan? Adrienne deploys a proven, creative and fun
              process that enables a business to grow and an organization to
              thrive.
            </p>
          </TabPanel>
          <TabPanel>
            <p>
              Culture is everything in a business. It provides the platform for
              trust and alignment, and sets an organization up for success.
              Adrienne can help you to create a warm, hopeful and performance
              driven environment where people are inspired to seize
              opportunities, overcome challenges and work collaboratively
              together. How does this happen? Through effective communication
              approaches, exciting activities and transparency. Adrienne will
              help you deliver!
            </p>
          </TabPanel>
          <TabPanel>
            <p>
              Leading through change, challenge and chaos? Don’t get left
              behind. Learn and deliver skills and tactics to successfully guide
              your organization through even the most difficult situation.
            </p>
          </TabPanel>
          <TabPanel>
            <p>
              As a C-suite executive, you are challenged with extraordinary
              responsibilities. Adrienne can help you to define and focus on
              your personal and professional goals in a safe, supportive and
              encouraging environment. This is all about you and a gift to
              yourself!
            </p>
          </TabPanel>
          <TabPanel>
            <p>
              Want to be that executive that delivers powerful, compelling and
              visually attractive presentations? Learn skills to project
              confidence and provide clear messaging that employees want and
              need. Gain support with creating the presentation that delivers
              maximum impact. Inspire your colleagues and own the stage!
              Adrienne excels in delivering creative and thought-provoking
              events. Both professional and personal, she can help you achieve
              your goals that are different, exciting, fun and memorable.
            </p>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
}
