import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import logo from "../images/logo.png"
import headerBg from "../images/header-bg.png"
import mainBannerBg from "../images/main-banner-bg.png"

export default function MainBanner() {
  return (
    <div className="main-banner">
      <img alt="Accelerate C-Suite Coaching Logo" className="main-banner__header-logo" src={logo} />
      <img alt="Sky Background"  className="main-banner__header-bg" src={headerBg} />
      <img alt="Sky Background Secondary" className="main-banner__bg" src={mainBannerBg} />
        <div className="content">
          <h1 className="content__headline">Rethink Executive Excellence</h1>
          {/* <h1 className="content__headline">Results Maximized</h1> */}
          <p>Elevate your leadership with masterful C-Suite coaching</p>
          <div className="content__cta-container">
            <AnchorLink stripHash className="button" to="/#Services">
              Services
            </AnchorLink>
            <AnchorLink stripHash className="button" to="/#contact">
              Contact Me
            </AnchorLink>
          </div>
        </div>
      </div>
  )
}
