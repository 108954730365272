import React from "react"

import portrait from "../images/adrienne-portrait.jpg"
import speaking from "../images/adrienne-speaking.jpg"

export default function AboutSection() {
  return (
    <div id="about" className="about-section">
      <div className="content">
        <p>
<strong>Adrienne Stevens</strong> is a 4-time president/CEO across Fortune 200 public, private and family-held organizations. Known as a smart, intuitive and engaged leader with a record of guiding teams that deliver best-in-class performance, she is energetic and passionate about <strong>motivating and helping people become the best they can be.</strong>
        </p>
        <p>
Coaching leaders and organizations for more than 25 years, Adrienne’s proven approach <strong>inspires purpose, ignites performance and creates lasting impact,</strong> especially during times of change and challenge. Her ability to connect provides immediate results that c-suite executives need the most: </p>

<p>Reality Check / Overcoming Blind Spots</p>

<p>Constructive Conflict Management</p>

<p>Planning, Execution and Accountability</p>

<p>“Soft Skills” Development – Motivation, Compassion and Persuasion</p>
      </div>
      <div className="about-section__images">
        <img alt="Adrienne Stevens" src={portrait} />
        <img alt="Adrienne keynote speaking at conference" src={speaking} />
      </div>

      <div className="about-section__specialties-group">
        <div className="about-section__specialties">
          <h3 className="about-section__heading">Specialites Include</h3>
          <ul className="about-section__specialties-list">
            <li>Scale for Growth / Transformation</li>
            <li>Strategic Planning &amp; Execution</li>
            <li>New Product Development, Branding and Marketing Launch</li>
            <li>Creating Diversity, Inclusion and Equity</li>
            <li>Global Manufacturing, E-Commerce, Distribution, Aerospace, Automotive, Defense Sectors</li>
            <li>Keynote Speaker &amp; Experiential Events</li>
            <li>Mergers, Acquisitions, Facilitation &amp; Integration</li>
          </ul>
        </div>

        <div className="about-section__media">
          <h3 className="about-section__heading">Media</h3>
          <div className="media-link">
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.mlive.com/business/west-michigan/2018/02/executive_embraces_change_wher.html"
            >
              <h3 className="media-link__heading">
                Executive Leads and Embraces Change Wherever She Lands
              </h3>
              <p className="media-link__source">mlive.com</p>
            </a>
          </div>
          <div className="media-link">
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.womenslifestyle.com/2016/01/06/312225/creative-collaboration-with-adrienne-stevens"
            >
              <h3 className="media-link__heading">Creative Collaboration</h3>
              <p className="media-link__source">womenslifestyle.com</p>
            </a>
          </div>
          <div className="media-link">
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.gvsu.edu/gvnext/2016/world-trade-week-speaker-we-need-speed-9393.htm"
            >
              <h3 className="media-link__heading">
                World Trade Week Speaker: We Need Speed
              </h3>
              <p className="media-link__source">gvsu.edu</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
