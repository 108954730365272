import React from "react"
import adrienneBg from '../images/adrienne.jpg';

export default function AdrienneSection() {
  return (
    <div id="Adrienne" className="adrienne-section">
      <img alt="Adrienne Stevens" className="adrienne-section__bg" src={adrienneBg} />
    </div>
  )
}
