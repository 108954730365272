import React from "react"
import testimonialsBg from "../images/testimonials-bg.png"

import {
  Dot,
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"

export default function testimonials() {
  return (
    <div id="Testimonials" className="testimonials">
      <img
        alt="Testimonials Background Image"
        className="testimonials__bg"
        src={testimonialsBg}
      />
      <div class="testimonials__content">
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={85}
          totalSlides={6}
          infinite={true}
          isPlaying={true}
        >
          <DotGroup />
          <Slider>
            <Slide
              className="testimonial__slide"
              classNameHidden="testimonial__slide--hidden"
              classNameVisible="testimonial__slide--visible"
              index={0}
            >
              <blockquote className="testimonial__quote">
                <em>"</em>
                Adrienne is a highly motivated and inspiring leader who has the
                ability create and present a vision and lead an organization to
                embrace that vision.
                <em>"</em>
              </blockquote>
              <div className="testimonial__source">
                <p className="testimonial__name">Tom Nakfoor</p>|
                <p className="testimonial__company">COO, HexArmor</p>
              </div>
            </Slide>
            <Slide
              className="testimonial__slide"
              classNameHidden="testimonial__slide--hidden"
              classNameVisible="testimonial__slide--visible"
              index={1}
            >
              <blockquote className="testimonial__quote">
                <em>"</em>
                Adrienne is a dynamic, visionary and passionate leader who works
                vigorously to lead an organization to extraordinary success.
                (Ordinary isn’t even in her vocabulary!)
                <em>"</em>
              </blockquote>
              <div className="testimonial__source">
                <p className="testimonial__name">Michelle Stevenson</p>|
                <p className="testimonial__company">
                  Executive Director of Marketing, Davenport University
                </p>
              </div>
            </Slide>
            <Slide
              className="testimonial__slide"
              classNameHidden="testimonial__slide--hidden"
              classNameVisible="testimonial__slide--visible"
              index={2}
            >
              <blockquote className="testimonial__quote">
                <em>"</em>
                Adrienne is talented, creative and accomplished; but more
                importantly she is a very intuitive and gifted leader. Her
                natural people skills and cultivating leadership style has
                earned my respect in our professional relationship together.
                <em>"</em>
              </blockquote>
              <div className="testimonial__source">
                <p className="testimonial__name">Heather Frank</p>|
                <p className="testimonial__company">
                  Chapter Director, YPO / Gold West Michigan
                </p>
              </div>
            </Slide>
            <Slide
              className="testimonial__slide"
              classNameHidden="testimonial__slide--hidden"
              classNameVisible="testimonial__slide--visible"
              index={3}
            >
              <blockquote className="testimonial__quote">
                <em>"</em>
                Adrienne possesses a relentless curiosity and passion for
                lifelong learning, a belief that business is a force for good
                and an unstoppable drive toward changing the world.
                <em>"</em>
              </blockquote>
              <div className="testimonial__source">
                <p className="testimonial__name">
                  Roger A. Nadeau, Major General, U.S. Army (Retired)
                </p>
                |
                <p className="testimonial__company">
                  Plasan US, Board of Directors
                </p>
              </div>
            </Slide>
            <Slide
              className="testimonial__slide"
              classNameHidden="testimonial__slide--hidden"
              classNameVisible="testimonial__slide--visible"
              index={4}
            >
              <blockquote className="testimonial__quote">
                <em>"</em>
                Adrienne builds strong teams and is able to to transform an
                organization in need of change or growth. If you making an
                investment in a leader then Adrienne is one of the best
                investments you can make.
                <em>"</em>
              </blockquote>
              <div className="testimonial__source">
                <p className="testimonial__name">Bill Benson</p>|
                <p className="testimonial__company">Executive Recruiter</p>
              </div>
            </Slide>
            <Slide
              className="testimonial__slide"
              classNameHidden="testimonial__slide--hidden"
              classNameVisible="testimonial__slide--visible"
              index={5}
            >
              <blockquote className="testimonial__quote">
                <em>"</em>
                Adrienne is a passionate leader who knows how to create and
                drive a vision throughout an organization. She has a relentless
                energy that fuels her teams and she is willing to make the tough
                decisions needed in challenging environments.
                <em>"</em>
              </blockquote>
              <div className="testimonial__source">
                <p className="testimonial__name">Matt Toburen</p>|
                <p className="testimonial__company">
                  Vice President, Tryon Solutions
                </p>
              </div>
            </Slide>
          </Slider>
        </CarouselProvider>
      </div>
    </div>
  )
}
