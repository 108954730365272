import React from "react"
import footerBg from "../images/footer.png"
import mailIcon from "../images/mail.png"
import phoneIcon from "../images/phone.png"

import facebook from "../images/facebook.png"
import linkedIn from "../images/linkedin.png"
import instagram from "../images/instagram.png"

export default function FooterSection() {
  return (
    <div id="contact" className="footer">
      <img alt="Footer Background Image" className="footer__bg" src={footerBg} />
      <div className="footer__content">
        <h2 className="footer__name">Adrienne L. Stevens</h2>
        <a
          className="footer__email"
          href="mailto:AStevens@AccelerateCSuite.com"
        >
          <img alt="Email Icon" src={mailIcon} />
          AStevens@AccelerateCSuite.com
        </a>
        <a className="footer__phone" href="tel:616-254-9090">
          <img alt="Phone Icon" src={phoneIcon} />
          +1 616 254 9090
        </a>

        <div className="footer__social-links">
          <a
            className="footer__social-link"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/adriennestevens/"
            target="_blank"
          >
            <img alt="LinkedIn Logo" src={linkedIn} />
          </a>
          <a
            className="footer__social-link"
            rel="noopener noreferrer"
            href="https://www.facebook.com/adrienne.stevens"
            target="_blank"
          >
            <img alt="Facebook Logo" src={facebook} />
          </a>
          <a
            className="footer__social-link"
            rel="noopener noreferrer"
            href="https://www.instagram.com/adriennelstevens/"
            target="_blank"
          >
            <img alt="Instagram Logo" src={instagram} />
          </a>
        </div>
      </div>
    </div>
  )
}
